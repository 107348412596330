import { render, staticRenderFns } from "./ReferenceWidget.vue?vue&type=template&id=05098015&scoped=true&"
import script from "./ReferenceWidget.vue?vue&type=script&lang=js&"
export * from "./ReferenceWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05098015",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05098015')) {
      api.createRecord('05098015', component.options)
    } else {
      api.reload('05098015', component.options)
    }
    module.hot.accept("./ReferenceWidget.vue?vue&type=template&id=05098015&scoped=true&", function () {
      api.rerender('05098015', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/screen/components/Reference/ReferenceWidget.vue"
export default component.exports
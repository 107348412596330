var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm._l(_vm.references, function(reference, index) {
            return [
              _c("v-tab", { key: index }, [
                _vm._v(_vm._s(reference.response.name))
              ])
            ]
          })
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.references, function(reference, index) {
          return _c("v-tab-item", { key: index, staticClass: "pt-2" }, [
            _c(
              "div",
              {
                staticClass: "overflow-y-auto overflow-x-hidden",
                staticStyle: { "max-height": "485px" }
              },
              [
                _c("psi-detail-card", {
                  attrs: {
                    items: _vm.transformFlags(reference.flags),
                    headingTitle:
                      _vm.$_.get(reference, "response.name") + " - Flags"
                  }
                }),
                _c("psi-detail-card", {
                  attrs: {
                    items: _vm.transformDetails(reference.response),
                    headingTitle:
                      _vm.$_.get(reference, "response.name") + " - Details"
                  }
                })
              ],
              1
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <v-tabs v-model="tab">
            <template v-for="(reference, index) in references">
                <v-tab :key="index">{{ reference.response.name }}</v-tab>
            </template>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="(reference, index) in references"
                :key="index"
                class="pt-2"
            >
                <div
                    class="overflow-y-auto overflow-x-hidden"
                    style="max-height: 485px"
                >
                    <psi-detail-card
                        :items="transformFlags(reference.flags)"
                        :headingTitle="`${$_.get(
                            reference,
                            'response.name'
                        )} - Flags`"
                    ></psi-detail-card>
                    <psi-detail-card
                        :items="transformDetails(reference.response)"
                        :headingTitle="`${$_.get(
                            reference,
                            'response.name'
                        )} - Details`"
                    ></psi-detail-card>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
export default {
    name: "reference-widget",
    components: {},
    props: {
        references: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "rental",
        },
    },
    data() {
        return {
            tab: null,
        };
    },
    methods: {
        transformFlags(flags) {
            return flags.map(({ flag, severity }) => {
                return {
                    icon: "mdi-flag",
                    type: "chip",
                    text: flag,
                    label: "",
                    class: "chip--flag align-center d-flex ",
                    color:
                        severity === "critical"
                            ? "error darken-1"
                            : "warning darken-2",
                };
            });
        },
        getItemIcon({ key = "" }) {
            return this.$config(`icons.${key.replace(/\./gi, "_")}`);
        },
        getItemType({ type = "text" }) {
            return type;
        },
        getItemLabel({ key = "" }) {
            const appends =
                key.split(".").length === 2
                    ? ` (${this.$_.upperFirst(key.split(".").pop())})`
                    : "";

            return this.$_.titleCase(key.split(".").shift()) + appends;
        },
        getItemFormatted(details, { key, format = "text" }) {
            switch (format) {
                case "currency":
                    return this.$dollarFormat(this.$_.get(details, key));
                case "uppercase":
                    return this.$_.get(details, key).toUpperCase();
                case "boolean":
                    return this.$_.get(details, key) ? "Yes" : "No";
                case "text":
                    return this.$_.get(details, key);
            }
        },
        getItemColor(details, { key, question_format = "Positive Bad" }) {
            const colors = {
                negative: {
                    "Positive Good": "error",
                    "Positive Bad": "success",
                },
                positive: {
                    "Positive Good": "success",
                    "Positive Bad": "error",
                },
            };
            return this.$_.get(details, key, false)
                ? colors.positive[question_format]
                : colors.negative[question_format];
        },
        transformDetails(details) {
            let transformed = [];
            const referenceData = this.$config(
                `screen.${this.type}ReferenceTransform`
            );
            referenceData.forEach((item) => {
                if (this.$_.has(item, "key")) {
                    let transformedItem = {
                        icon: this.getItemIcon(item),
                        type: this.getItemType(item),
                        label: this.getItemLabel(item),
                        text: this.getItemFormatted(details, item),
                    };
                    if (this.$_.get(item, "format", "") === "boolean") {
                        transformedItem["color"] = this.getItemColor(
                            details,
                            item
                        );
                    }
                    transformed.push(transformedItem);
                } else if (this.$_.has(item, "heading")) {
                    transformed.push(item);
                }
            });
            return transformed;
        },
    },
};
</script>

<style scoped>
</style>